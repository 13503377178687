<template>
    <div class="InventoryRecords f-cl">
        <el-page-header @back="backPrivious" content="库存记录"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="f-c-b mb-40">
                    <div v-if="isScreen">筛选到：{{ total }} 条相关记录</div>
                    <div style="color: #333;font-size: 14px;font-weight: 500;" v-else>总条数：<span
                            style="font-size: 24px;color: #0363FA;">{{
                                total }}</span></div>
                    <div class="screenBtn" @click="drawer = true"></div>
                </div>
                <template v-if="recordsList && recordsList.length" style="border:1px solid red;">
                    <div class="boxShadow paddingTB-60 paddingLR-40 borderRa-10" style="width: 91.8%;">
                        <router-link class="item f-c-b" v-for="(item, index) of recordsList" :key="index" :to="{
                            name: 'InventoryRecordsDetails', query: {
                                key: $UrlEncode.encode(JSON.stringify({
                                    id: item.stockRecordId
                                }))
                            }
                        }">
                            <div class="f-c-b w--80">
                                <div class="w--50">
                                    <div class="f-cl-b">
                                        <div class="f-c mb-15">
                                            <div class="col-66">产品名称：</div>
                                            <div style="align-self: flex-start;width: 50%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                                <span>{{ item.productName }}</span>
                                                <span
                                                    v-if="item.customerUseName && (item.productName != item.customerUseName)">({{
                                                        item.customerUseName
                                                    }})</span>
                                            </div>
                                        </div>
                                        <div class="f-c">
                                            <div class="col-66">产品编号：</div>
                                            <div style="align-self: flex-start;width: 50%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                                <span>{{ item.productNumber ? item.productNumber : '未设置' }}</span>
                                                <span
                                                    v-if="item.customerUseNumber && (item.productNumber != item.customerUseNumber)">({{
                                                        item.customerUseNumber
                                                    }})</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w--50">
                                    <div class="f-cl">
                                        <div class="f-c mb-15">
                                            <div class="col-66">操作人员：</div>
                                            <div style="align-self: flex-start;width: 50%;">
                                                <span>{{ item.changeUsername }}</span>
                                            </div>
                                        </div>
                                        <div class="f-c mb-15">
                                            <div class="col-66">修改时间：</div>
                                            <div style="align-self: flex-start;width: 50%;">
                                                <span>{{ item.createDate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="w--20 f-cl-c">
                                <span class="mb-5 fs-14">{{ item.stockCtypeText }}</span>
                                <span class="marginTB-5 fs-14 col-red"
                                    v-if="item.stockCtype != 'CINP' && item.stockCtype != 'COUT' && item.cancelFlag == 'Y'">已作废</span>
                                <span class="mt-5 fs-14"
                                    :style="{ color: item.stockChangeType == '+' ? '#ff8d00' : 'red' }">库存数量：{{
                                        item.stockChangeType +
                                        item.stockChangeNumber
                                    }}</span>
                            </div>
                        </router-link>

                    </div>
                    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                        @pagination="fetchData" />
                </template>
                <el-empty v-else description="暂无内容！"></el-empty>
            </div>
        </div>
        <!-- <template v-if="recordsList && recordsList.length">
            <el-button plain @click="drawer = true" class="screen f-c-c">
                <i class="el-icon-finished"></i>
                <span>记录筛选</span>
            </el-button>
            <span class="mb-10 col-red" v-show="isScreen">筛选到：{{ total }} 条相关记录</span>
            <router-link :to="{
                name: 'InventoryRecordsDetails', query: {
                    key: $UrlEncode.encode(JSON.stringify({
                        id: item.stockRecordId
                    }))
                }
            }" class="record-item f-c-b" v-for="(item, index) of recordsList" :key="index">
                <div class="left f-cl">
                    <div class="left-item f-c">
                        <div>产品名称：</div>
                        <div style="align-self: flex-start;width: 90%;">
                            <span>{{ item.productName }}</span>
                            <span v-if="item.customerUseName && (item.productName != item.customerUseName)">({{
                                item.customerUseName
                            }})</span>
                        </div>
                    </div>
                    <div class="left-item f-c">
                        <div>产品编号：</div>
                        <div style="align-self: flex-start;width: 90%;">
                            <span>{{ item.productNumber ? item.productNumber : '无' }}</span>
                            <span v-if="item.customerUseNumber && (item.productNumber != item.customerUseNumber)">({{
                                item.customerUseNumber
                            }})</span>
                        </div>
                    </div>
                    <div class="left-item">操作人员：<span>{{ item.changeUsername }}</span></div>
                    <div>修改时间：<span>{{ item.createDate }}</span></div>
                </div>
                <div class="right f-cl-c">
                    <span>{{ item.stockCtypeText }}</span>
                    <span v-if="item.stockCtype != 'CINP' && item.stockCtype != 'COUT' && item.cancelFlag == 'Y'"
                        style="color:red; font-size: 14px;">已作废</span>
                    <span :style="{ color: item.stockChangeType == '+' ? '#ff8d00' : 'red' }">库存数量：{{
                        item.stockChangeType +
                        item.stockChangeNumber
                    }}</span>
                </div>
            </router-link>
            <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                @pagination="fetchData" />
        </template>
        <el-empty v-else description="暂无内容！"></el-empty> -->

        <el-drawer title="记录筛选" :visible="drawer" :direction="direction" class="drawer-box" :before-close="handleClose">
            <el-form ref="drawerForm" :model="drawerForm" class="drawer-form">
                <el-form-item label="产品名称：" prop="productName">
                    <ChineseCharactersInput :modelValue="drawerForm.productName" @getValue="getValue"
                        :type="'InventoryRecordsProductName'" :placeholderValue="'请输入产品名称'">
                    </ChineseCharactersInput>
                </el-form-item>
                <el-form-item label="产品编号：" prop="productNumber">
                    <ChineseCharactersInput :modelValue="drawerForm.productNumber" @getValue="getValue"
                        :type="'InventoryRecordsProductNumber'" :placeholderValue="'请输入产品编号'">
                    </ChineseCharactersInput>
                </el-form-item>
                <el-form-item label="修改人：" prop="modifyPersons">
                    <el-input v-model.trim="drawerForm.modifyPersons" disabled placeholder="请选择修改人">
                        <template slot="append">
                            <el-button @click="dialogShow = true"><i class="el-icon-arrow-right"></i></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="修改时间："></el-form-item>
                <el-form-item prop="searchBeforeDate">
                    <el-date-picker v-model="drawerForm.searchBeforeDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择起始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="searchAfterDate">
                    <el-date-picker v-model="drawerForm.searchAfterDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button @click="resetForm('drawerForm')">重置</el-button>
                    <el-button type="primary" @click="submitFilter(1, 10, 'screen')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
        <ModifyPersonalDialog :dialogShow="dialogShow" :isHandleReset="isHandleReset" @closeDialog="closeDialog"
            @getUserIdList="getUserIdList">
        </ModifyPersonalDialog>
    </div>
</template>

<script>

import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin'
import { InventoryStockCtypeClass } from '@/utils/InventoryStockCtypeClass'
// 组件懒加载--只要页面一打开时不需要马上显示且需要用户点击才加载的均使用组件懒加载
// const ModifyPersonalDialog = () => import(/* webpackChunkName: "dialogInfo" */ '../components/ModifyPersonal.vue');
export default {
    name: 'InventoryRecords',
    components: {
        ModifyPersonalDialog: () => import(/* webpackChunkName: "dialogInfo" */ '../components/ModifyPersonal.vue'),
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    mixins: [PaginationRetention1],
    data() {
        return {
            drawer: false,
            direction: 'rtl',
            drawerForm: {
                productName: '',//产品名称
                productNumber: '',//产品编号
                searchUserIdList: [],//修改人数组
                searchBeforeDate: '',//起始时间
                searchAfterDate: '',//结束时间
                modifyPersons: '',//修改人
            },
            recordsList: [],
            dialogShow: false,//修改人弹窗
            isScreen: false,//是否开启了筛选
            isHandleReset: false,//外部筛选盒子是否点击了重置
            enterpriseStateInfo: {},//权限

        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo');
        let params = this.$route.query.key ? JSON.parse(this.$UrlEncode.decode(this.$route.query.key)) : '';
        if (params.type == 'search') {
            this.drawerForm.productName = params.searchValue;
            this.submitFilter(1, 10)
        } else {
            this.fetchData()
        }
    },

    methods: {
        getDate() {
            this.$getStorage('InventoryRecordsDrawerForm') ? this.drawerForm = this.$getStorage('InventoryRecordsDrawerForm') : ''
            this.$getStorage('InventoryRecordsDrawerForm') ? this.submitFilter(this.paging, this.pagingSize) : this.productStockList(this.paging, this.pagingSize);
        },
        // 获取搜索框数据且调接口
        getValue(data) {
            data.type == 'InventoryRecordsProductName' ? this.drawerForm.productName = data.value : this.drawerForm.productNumber = data.value
        },
        // 处理数据
        handleRecords(list) {
            return list.forEach(item => {
                item.stockCtypeText = InventoryStockCtypeClass.getStockCtype(item.stockCtype)
                item.stockChangeNumber = item.stockChangeNumber.toFixed(2)
            })
        },
        // 获取库存列表
        productStockList(page = this.page, size = this.size) {
            let that = this;
            that.$http.productStockList({
                changeUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                current: page,
                size
            }).then(res => {
                that.handleRecords(res.data.records)
                that.recordsList = res.data.records;
                that.total = res.data.total;
                that.currentPage = res.data.current;
            })
        },
        // 关闭记录筛选
        handleClose() {
            this.drawer = false
        },
        // 提交记录筛选
        submitFilter(page = this.page, size = this.size, type) {
            let that = this,
                { productName, productNumber, searchUserIdList, searchBeforeDate, searchAfterDate } = that.drawerForm;
            that.$http.productStockSearch({
                changeUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                productName,
                productNumber,
                searchUserIdList,
                searchBeforeDate,
                searchAfterDate,
                current: type == 'screen' ? that.page : page,
                size
            }).then(res => {
                if (productName || productNumber || searchUserIdList && searchUserIdList.length || searchBeforeDate || searchAfterDate) {
                    that.isScreen = true
                } else {
                    that.isScreen = false
                }
                that.$setStorage('InventoryRecordsDrawerForm', that.drawerForm)
                that.handleRecords(res.data.records)
                that.recordsList = res.data.records;
                that.total = res.data.total;
                that.currentPage = res.data.current;
                that.drawer = false;
            })
        },
        // 重置记录筛选
        resetForm(formName) {
            this.drawerForm.searchUserIdList = []
            this.drawerForm.modifyPersons = '';
            this.$refs[formName].resetFields();
            this.isHandleReset = true
        },
        // 关闭弹出窗
        closeDialog(data) {
            this.dialogShow = data;
        },
        // 获取修改人数组
        getUserIdList(data) {
            this.drawerForm.searchUserIdList = data.searchUserIdList
            this.drawerForm.modifyPersons = data.userNames
        }
    },
}

</script>
<style lang='less' scoped>
@import '../style/drawerBox.less';

.InventoryRecords {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .screenBtn {
            width: 120px;
            height: 60px;
            background: url('../../../assets/supply/screen.png');
            cursor: pointer;
        }

        .item {
            padding: 30px;
            background: #F8F8F8;
            border-radius: 10px;
            margin-bottom: 30px;

            // span {
            //     width: 100%;
            //     text-align: center;
            //     line-height: 60px;
            //     color: #333;
            //     font-weight: 400;
            //     font-size: 16px;
            //     overflow: hidden;
            //     white-space: nowrap;
            //     text-overflow: ellipsis;
            //     // border: 1px solid red;
            // }
        }
    }

    .screen {
        width: 10%;
        align-self: flex-end;
        margin-bottom: 10px;
    }

    .record-item {
        padding: 15px 50px 15px 25px;
        border-bottom: 1px solid #888;

        .left {
            width: 80%;

            .left-item {
                margin-bottom: 10px;
            }

            span {
                color: #999;
            }
        }

        .right {
            span:not(:last-child) {
                margin-bottom: 20px;
            }

            span:first-child {
                color: #999;
                font-weight: bold;
            }
        }

    }

    .record-item:hover {
        background-color: aliceblue;
        cursor: pointer;
    }
}
</style>

